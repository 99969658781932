/* Override the size dropdown to show pixel values */
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value);  /* Use the data-value attribute to show pixel size */
  font-size: 14px; /* Adjust font size if needed */
}

/* Specifically style each pixel value in the dropdown */
.ql-size .ql-picker-item[data-value="10px"]::before {
  content: '10px';
}
.ql-size .ql-picker-item[data-value="12px"]::before {
  content: '12px';
}
.ql-size .ql-picker-item[data-value="16px"]::before {
  content: '16px';
}
.ql-size .ql-picker-item[data-value="20px"]::before {
  content: '20px';
}
.ql-size .ql-picker-item[data-value="24px"]::before {
  content: '24px';
}
.ql-size .ql-picker-item[data-value="30px"]::before {
  content: '30px';
}
.ql-size .ql-picker-item[data-value="36px"]::before {
  content: '36px';
}

/* Font dropdown customization */
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  font-size: 14px;
}
/* Customize the font labels in the dropdown */
/* Force font labels in the font picker dropdown */
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  content: 'Arial';
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
  content: 'Times New Roman';
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier"]::before {
  content: 'Courier';
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
  content: 'Georgia';
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before {
  content: 'Verdana';
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before {
  content: 'Tahoma';
}

/* Ensure the selected font is applied */
.ql-font-arial {
  font-family: 'Arial', sans-serif;
}
.ql-font-times-new-roman {
  font-family: 'Times New Roman', serif;
}
.ql-font-courier {
  font-family: 'Courier', monospace;
}
.ql-font-georgia {
  font-family: 'Georgia', serif;
}
.ql-font-verdana {
  font-family: 'Verdana', verdana;
}
.ql-font-tahoma {
  font-family: 'Tahoma', sans-serif;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
